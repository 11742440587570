import * as React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import Intro from './components/Intro';
import ClickAndPlay from './components/ClickAndPlay';
import Highlights from './components/Highlights';
import FastPerformance from './components/FastPerformance'
import Pricing from './components/Pricing';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import getMPTheme from './theme/getMPTheme';
import NonUniform from './components/NonUniform';
import ExtensiveDocumentation from './components/ExtensiveDocumentation';
import Trial from './components/Trial';
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import { initializePaddle } from '@paddle/paddle-js';
import { useEffect } from 'react';
import { IsRegionSupported } from './components/SupportedRegion';
const MessageBanner = ({ open, setOpen, message, state }) => {
  const [isVisible, setIsVisible] = useState(open);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    if (open) {
      setIsVisible(true);
      setFadeOut(false);
      const timer = setTimeout(() => {
        setFadeOut(true);
        setTimeout(() => {
          setOpen(false);
        }, 500);
      }, 15000);

      return () => clearTimeout(timer);
    } else {
      setIsVisible(false);
    }
  }, [open, setOpen]);

  const handleMouseEnter = () => {
    if (fadeOut) {
      setFadeOut(false);
      setIsVisible(true);
    }
  };
  return (
    <Box
      sx={{
        width: '100%',
        position: 'fixed',
        top: 0,
        zIndex: 9999,
        padding: 8,
        pointerEvents: 'none',
        opacity: fadeOut ? 0 : 1,
        transition: 'opacity 0.5s ease',
      }}
      onMouseEnter={handleMouseEnter}
    >
      <Collapse in={isVisible && !fadeOut}>
        <Alert
          severity={state}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
              sx={{ pointerEvents: 'auto' }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2, pointerEvents: 'auto' }}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
  );
};
export default function MainPage() {
  const [mode, setMode] = React.useState('light');
  const [open, setOpen] = React.useState(true);
  const MPTheme = createTheme(getMPTheme(mode));
  const [seed, setSeed] = useState(1);
  const location = useLocation();
  const update = () => {
    setSeed(Math.random());
  }

  const [paddle, setPaddle] = useState();
  const [supportedRegion, setSupportedRegion] = useState(false);
  const [doneLoading, setLoadingStatus] = useState(false);

  React.useEffect(() => {
    const systemPrefersDark = window.matchMedia(
      '(prefers-color-scheme: dark)',
    ).matches;
    setMode(systemPrefersDark ? 'dark' : 'light');
    initializePaddle({
      environment: 'production',
      token: 'live_c23e0af7acee2a24ba8abf64bc4',
      checkout: {
        settings: {
          variant: "one-page",
          successUrl: "https://magicdefocus.com/success",
          showAddDiscounts: false,
          theme: systemPrefersDark ? "dark" : "light",
        }
      }
    }).then(
      (paddleInstance) => {
        if (paddleInstance) {
          setPaddle(paddleInstance);
        }
      },
    );

    IsRegionSupported().then(
      (isSupported) => {
        setSupportedRegion(isSupported);
        setLoadingStatus(true);
      }
    )

    CookieConsent.run({

      categories: {
        necessary: {
          enabled: true,
          readOnly: true
        },
        functional: {}
      },

      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: 'Your privacy',
              description: 'Our website uses functional cookies to enhance your experience. Specifically, we use cookies related to the Vimeo iframe to provide you with video content. These cookies help us remember your preferences and improve the functionality of our site.',
              acceptAllBtn: 'Accept',
              acceptNecessaryBtn: 'Reject',
            },
          }
        }
      },
      onConsent: ({ cookie }) => {
        update();
      }
    });
  }, []);
  return (
    <ThemeProvider theme={MPTheme}>
      <CssBaseline enableColorScheme />
      {((!supportedRegion && doneLoading) || (location.pathname === '/invalid-region')) && <MessageBanner open={open} setOpen={setOpen} success={false} message={"You are within either the USA or Canada, which is unfortunately not yet supported. Work is currently being done to ensure you are able to use MagicDefocus2 as well. Please subscribe to the newsletter to be updated when that happens."} />} { }
      {location.pathname === '/' && <MessageBanner open={open} setOpen={setOpen} state={"info"} message={"A significant upgrade for MagicDefocus2 is being worked on at the moment. Thank you for your continued interest and support. Want to be notified when it's ready? Sign up for our newsletter and receive an update notification once the new version is live."} />} { }
      {location.pathname === '/success' && <MessageBanner open={open} setOpen={setOpen} state={"success"} message={"Thank so much for subscribing. Your subscription was received successfully. Please keep an eye on your mail inbox for a message with your license key. If it takes longer than 30 minutes, please do not hesitate to send an email to support@vinkvfx.com."} />} { }
      {location.pathname === '/invalid-mail' && <MessageBanner open={open} setOpen={setOpen} state={"error"} message={"The mail entered is not a valid mail, please try again."} />} { }
      {location.pathname === '/trial-already-requested' && <MessageBanner open={open} setOpen={setOpen} state={"error"} message={"A trial has already been requested. Please check your email with a mail regarding the trial."} />} { }
      {location.pathname === '/trial-requested' && <MessageBanner open={open} setOpen={setOpen} state={"success"} message={"The trial has been requested successfully. Please keep an eye on your mail inbox for a message with your license key. If it takes longer than 30 minutes, please do not hesitate to send an email to support@vinkvfx.com."} />} { }


      <Hero key={seed} />
      <AppAppBar />
      <div>
        <Divider />
        <Intro />
        <Divider />
        <ClickAndPlay />
        <Divider />
        <FastPerformance />
        <Divider />
        <NonUniform />
        <Divider />
        <ExtensiveDocumentation />
        <Divider />
        <Highlights />
        <Divider />
        {doneLoading && <Trial isSupported={supportedRegion} />}
        <Divider />
        {doneLoading && <Pricing paddle={paddle} isSupported={supportedRegion} />}
        <Divider />
        <FAQ />
        <Divider />
        <Footer />
      </div>
    </ThemeProvider>
  );
}
