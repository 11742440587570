import * as React from 'react';
import { useState } from 'react';
import { Button, Box, Switch, FormControlLabel, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Checkout } from './Checkout';
const CustomSwitch = ({ isMonthly, onChange }) => {
  const theme = useTheme();

  const handleChange = (event) => {
    onChange(event.target.checked);
  };

  return (
    <Box display="flex" alignItems="center">
      <Chip label="Get 2 months free!" variant="outlined" style={{ marginRight: '10px' }} color={isMonthly ? 'default' : 'primary'} />
      <span style={{ marginRight: '-10px', color: isMonthly ? 'grey' : (theme.applyStyles("light") ? 'white' : 'black') }}>
        Annually
      </span>
      <Box display="flex" alignItems="center" justifyContent="center" width="60px">
        <FormControlLabel
          control={
            <Switch
              checked={isMonthly}
              onChange={handleChange}
              size="medium"
              sx={{
                '& .MuiSwitch-thumb': {
                  backgroundColor: theme.palette.primary.main,
                },
                '& .MuiSwitch-track': {
                  backgroundColor: theme.palette.primary.main
                },
                '&.Mui-checked .MuiSwitch-thumb': {
                  backgroundColor: theme.palette.primary.main,
                },
                '&.Mui-checked .MuiSwitch-track': {
                  backgroundColor: theme.palette.primary.main,
                },
                '&.MuiSwitch-root': {
                  color: theme.palette.primary.main,
                },
              }}
            />
          }
          label=""
          labelPlacement="start"
        />
      </Box>

      <span
        style={{
          marginLeft: '18px',
          color: isMonthly ? (theme.applyStyles("light") ? 'white' : 'black') : 'grey',
        }}
      >
        Monthly
      </span>
    </Box>
  );
};


const CheckoutHandler = ({ paddle, tier, isMonthly, isSupported }) => {
  const handleCheckout = () => {
    Checkout(paddle, tier.id, isMonthly);
  };
  return (
    <div>
      <Button
        fullWidth
        variant={tier.buttonVariant}
        color={tier.buttonColor}
        onClick={handleCheckout}
        disabled={!isSupported}
      >
        {tier.buttonText}
      </Button>
    </div>
  );
};


const tiers = [
  {
    title: 'Node-locked',
    id: "nodelocked",
    annual_price: '12,50',
    monthly_price: '15',
    billed_price: '150',
    description: [
      '1 node locked license',
      'GPU accelerated defocus',
      'Receive updates and new features',
      'Extensive documentation',
    ],
    buttonText: 'Subscribe',
    buttonVariant: 'contained',
    buttonColor: 'secondary',
    url: 'magicdefocus2-nodelocked'
  },
  {
    title: 'Floating',
    id: "floating",
    annual_price: '20,83',
    monthly_price: '25',
    billed_price: '249,96',
    description: [
      '1 floating GUI + render only license',
      '1 floating render only license',
      'GPU accelerated defocus',
      'Receive updates and new features',
      'Extensive documentation',
    ],
    buttonText: 'Subscribe',
    buttonVariant: 'contained',
    buttonColor: 'secondary',
    url: 'magicdefocus2-floating'
  },
  {
    title: 'Site-license',
    id: "site",
    annual_price: '250',
    billed_price: '3000',
    monthly_price: '300',
    description: [
      '100 floating GUI + render only licenses',
      '100 render only licenses',
      'GPU accelerated defocus',
      'Receive updates and new features',
      'Extensive documentation',
    ],
    buttonText: 'Subscribe',
    buttonVariant: 'contained',
    buttonColor: 'secondary',
    url: 'magicdefocus2-site'
  },
];

export default function Pricing({ paddle, isSupported }) {
  const [isMonthly, setIsMonthly] = useState(false);
  const handleReachOut = () => {
    window.location.href = "mailto:support@vinkvfx.com";
  };
  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary' }}
        >
          Pricing
        </Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>
          MagicDefocus2 offers several options. If you are a freelancer/individual the node-locked option is recommended. While for studio's the floating options are the best. Not sure what you need? You can always reach out to support.
        </Typography>
      </Box>
      <CustomSwitch isMonthly={isMonthly} onChange={setIsMonthly} />
      <Grid
        container
        spacing={3}
        sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}
      >
        {tiers.map((tier) => (
          <Grid
            size={{ xs: 12, sm: 4, md: 4 }}
            key={tier.title}
          >
            <Card
              sx={[
                {
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                },
              ]}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                  }}
                >
                  <Typography component="h3" variant="h2">
                    €{isMonthly ? tier.monthly_price : tier.annual_price}
                  </Typography>
                  &nbsp;
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    sx={{
                      textAlign: 'left', marginTop: '-4px',
                      marginLeft: '8px', lineHeight: '1.4'
                    }}
                  >
                    per month<br></br>
                    ex VAT<br></br>
                    {isMonthly ? 'billed monthly' : `billed annually at €${tier.billed_price}`}
                  </Typography>
                </Box>

                <Divider sx={{ my: 2, opacity: 0.8, borderColor: 'divider' }} />
                {tier.description.map((line) => (
                  <Box
                    key={line}
                    sx={{ py: 1, display: 'flex', gap: 1.5, alignItems: 'center' }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{ width: 20, color: 'primary.light' }}
                    />
                    <Typography variant="subtitle2" component={'span'}>
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <div style={{ width: '100%' }}>
                  <CheckoutHandler paddle={paddle} tier={tier} isMonthly={isMonthly} isSupported={isSupported} />
                </div>

              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          mt: 4,
          p: 2,
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 1,
          textAlign: 'center',
          backgroundColor: 'background.paper',
        }}
      >
        <Typography variant="h6" sx={{ mb: 1 }}>
          Looking for a company-wide license? Please reach out for custom pricing.
        </Typography>
        <Button variant="outlined" color="primary" onClick={handleReachOut}>
          Reach out
        </Button>
      </Box>
    </Container>
  );
}
